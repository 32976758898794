.NaughtyOrNice{
    background-color: #31e7e7;
    text-align: center;
    min-height: 50vh;
}

.nn-header {

    color: #371488;
    background: rgba(173, 9, 255, 0.35);
    padding: 50px;
    text-align: center;
}
